var Pages = Pages || {};
/* ================================
 * Home
 * ================================ */

Pages.home = function(app) {
	'use strict';

	var _ = this;
	var A = app;


	/* ================================
	 * Home
	 * ================================ */

	_.init = function() {
		console.log('home is init');
	};

};